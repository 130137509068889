<template>
  <div class="auth-wrapper auth-v2 mobile-only">
    <!-- Video Background -->
    <video autoplay muted loop id="bg-video">
      <source :src="backgroundVideo" type="video/mp4" />
      Seu navegador não suporta o elemento de vídeo.
    </video>

    <div class="d-flex justify-content-center align-items-center vh-100">
      <b-row class="auth-inner m-0 w-100 justify-content-center">
        <!-- Login-->
        <b-col sm="10" class="d-flex align-items-center auth-bg px-2 p-lg-2">
          <b-col sm="12" class="px-xl-2 mx-auto">
            <div class="text-center mb-2">
              <!-- Brand logo-->
              <b-link class="brand-logo">
                <b-img
                  fluid
                  :src="imgLogo2"
                  alt="Logo New X Coins"
                  style="max-height: 10rem"
                />
              </b-link>
              <!-- /Brand logo-->
            </div>
            <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
              Bem-vindo, Investidor!
            </b-card-title>
            <b-card-text class="mb-2">
              Estamos prontos para impulsionar seus ganhos hoje!
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- email -->
                <label for="login-password">{{ $t("E-mail") }}</label>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    mode="eager"
                    name="Email"
                    vid="email"
                    rules="required|min:4|max:80|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 || alertLogin ? false : null"
                      name="login-email"
                      placeholder="example@gmail.com"
                      @change="alertLogin = false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t("Senha") }}</label>
                    <b-link :to="{ name: 'forgot-password' }">
                      <small>{{ $t("Esqueceu sua senha?") }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    mode="eager"
                    name="Password"
                    vid="password"
                    rules="required|min:4|max:80"
                  >
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 || alertLogin ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Senha"
                        @change="alertLogin = false"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- alert -->
                <b-alert
                  v-height-fade.appear
                  :show="alertLogin"
                  class="mb-1"
                  variant="danger"
                >
                  <div class="alert-body">
                    {{ apiErrorMessage }}
                    <div>
                      {{ $t("Esqueceu sua senha?") }}
                      <b-link :to="{ name: 'forgot-password' }">
                        <small>{{ $t("Clique aqui") }}</small>
                      </b-link>
                    </div>
                  </div>
                </b-alert>
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid || loading"
                >
                  <div v-if="!loading">
                    {{ $t("Entrar") }}
                  </div>
                  <div v-else>
                    <b-spinner small type="grow"></b-spinner>
                    {{ $t("Carregando...") }}
                  </div>
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email, min, max } from "@/libs/validations";
import ProjectLogo from "@/layouts/logo/LogoProjectX.vue";
import GoogleLogo from "@/layouts/logo/LogoGoogle.vue";
import ProjectXLogo from "@/layouts/logo/LogoProjectX.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { mapGetters, mapActions } from "vuex";
import DeviceDetector from "device-detector-js";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Locale from "@/layouts/components/Locale.vue";

import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ProjectLogo,
    GoogleLogo,
    ProjectXLogo,
    ValidationProvider,
    ValidationObserver,
    Locale,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      loading: false,

      // validation rules
      required,
      email,
      min,
      max,

      //Alert
      apiErrorMessage: "",
      alertLogin: false,

      // Background video
      backgroundVideo: require("@/assets/videos/background.mp4"),
    };
  },
  computed: {
    ...mapGetters(["returnLocale"]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      return require("@/assets/images/business_plan/img_login3.png");
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === "dark") {
        return require("@/assets/images/logo/newxcoins_logo_light.png");
      } else {
        return require("@/assets/images/logo/newxcoins_logo_dark.png");
      }
    },
    imgLogo2() {
      return require("@/assets/images/logo/newxcoins_logo_light.png");
    },
  },
  watch: {
    returnLocale: function (oldVal, newVal) {
      localize(oldVal);
    },
  },
  mounted() {
    this.verifyInternet();
  },
  methods: {
    ...mapActions(["authenticateUser", "recoverUserData"]),
    verifyInternet() {
      setInterval(() => {
        if (!navigator.onLine) this.loading = false;
      }, 3000);
    },
    showAlert() {
      this.alertLogin = true;
    },
    hideAlert() {
      this.alertLogin = false;
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const data = {
            email: this.userEmail.toLowerCase(),
            password: this.password,
          };
          this.authenticateUser(data)
            .then(() => {
              this.recoverUserData()
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      icon: "CoffeeIcon",
                      variant: "success",
                      title:
                        this.$i18n.t("Bem-vindo ") +
                        "@" +
                        response.data.user.username,
                    },
                  });
                  this.$ability.update(response.data.user.roles);
                  this.loading = false;
                  this.$router.push("/");
                })
                .catch((error) => {
                  this.showAlert();
                  this.apiErrorMessage = error;
                  this.loading = false;
                });
            })
            .catch((error) => {
              this.showAlert();
              this.apiErrorMessage = error;
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.auth-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

#bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background: no-repeat;
  background-size: cover;
}

.auth-inner {
  width: 100%;
  max-width: 550px;
  background: rgba(0, 22, 27, 0.5); /* Manter a opacidade baixa */
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5); /* Aumentar a sombra */
  color: #fff; /* Tornar as letras mais legíveis com cor branca */
}

.auth-inner * {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Adicionar sombra a todos os textos dentro do card */
}

label {
  color: #fff; /* Tornar os labels brancos */
}

.small {
  color: #fff; /* Tornar os pequenos textos brancos */
}

.b-form-input, .b-form-group, .b-input-group, .b-button {
  background: rgb(0, 25, 39); /* Fundo escuro e semitransparente para inputs e botões */
  color: #fff; /* Tornar o texto dos inputs e botões brancos */
  border: 1px solid #fff; /* Bordas brancas para inputs e botões */
}

.b-link {
  color: #fff; /* Tornar os links brancos */
}

.b-alert {
  background: rgba(255, 0, 0, 0.5); /* Fundo semitransparente para alertas */
  color: #fff; /* Tornar o texto dos alertas branco */
}

.b-card-title, .b-card-text {
  color: #fff; /* Tornar o texto branco */
}

.b-card-title {
  font-size: 2rem; /* Aumentar o tamanho da fonte para destacar mais */
  font-weight: bold; /* Tornar o texto em negrito */
}

.b-card-text {
  font-size: 1.2rem; /* Aumentar o tamanho da fonte para destaque adicional */
}

@media (max-width: 767px) {
  .mobile-only {
    height: 100vh; /* Preencher toda a altura da tela em dispositivos móveis */
    border-radius: 0; /* Remover borda arredondada em dispositivos móveis */
    padding: 1rem; /* Ajustar padding para dispositivos móveis */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .auth-inner {
    height: 100vh; /* Preencher toda a altura da tela em dispositivos móveis */
    border-radius: 0; /* Remover borda arredondada em dispositivos móveis */
    padding: 1rem; /* Ajustar padding para dispositivos móveis */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
